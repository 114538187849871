import React from 'react';
import "./styles/App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faLinkedin, faTiktok, faDiscord} from '@fortawesome/free-brands-svg-icons'
import ParticlesOverlay from './ParticlesOverlay';

class App extends React.Component {
  render = () => {
    return (

      <div className="card">
        <div className="header">

        <div className="social">
          <a href="https://instagram.com/venturehacks" title="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon="fa-brands fa-instagram" style={{color: "#ffffff",}} />
              <FontAwesomeIcon icon={faInstagram} size="2xl" style={{color: "#ffffff",}} />
          </a>
          <a href="https://www.linkedin.com/company/venturehacksuw" title="LinkedIn" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2xl" style={{color: "#ffffff",}} />
          </a>
          <a href="https://www.tiktok.com/@venturehacks?_t=8fmLymQwsis&_r=1" title="Tiktok" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} size="2xl" style={{color: "#ffffff",}} />
          </a>
          <a href="https://discord.com/" title="Discord" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="2xl" style={{color: "#ffffff",}} />
          </a>
        </div>

        </div>
        <div className="content">
          {/* <StarOverlay numberOfSnowflakes={50} /> */}
          <ParticlesOverlay />
          <div className="logo">
            <a href=".">
              <img src={`${process.env.PUBLIC_URL}/images/venturehacks-logo.png`} alt="VENTUREHACKS" />
            </a>
          </div>

          <div className="title-holder">
            <h1>Where Innovation Meets Technology</h1>
            <p>Coming Soon! Wanna be a part of the team? Check out our hiring page below!</p>
          </div>
          <a href="https://venturehacksapplications.notion.site/venturehacksapplications/Join-the-Venture-Hacks-Team-ee578586605d4366ac1f4fadd3c579b0">
            <div className="cta">View Positions</div>
          </a>
        </div>
        <div className="footer">
        <div>
        VentureHacks 1.0.0<br />
        10.25.24 - 10.27.24
        </div>
        </div>
      </div>
    );
  }
}


export default App;
